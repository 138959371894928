<template>
  <v-container
    id="issues"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h1>{{ $t('Issues.pageTitle') }}</h1>
        <core-editable-issue-list
          v-if="hasPermission(permissions.GLOBAL_ISSUES)"
          module="GlobalIssues"
          icon="$vuetify.icons.userTag"
        />
        <core-editable-issue-list
          v-if="hasPermission(permissions.ISSUES)"
          module="Issues"
          icon="$vuetify.icons.tag"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Issues',
  metaInfo () {
    return {
      title: this.$t('Issues.pageTitle'),
    }
  },
}
</script>
